<template>
	<div class="main">
		<v-layout style="max-width: 900px; margin: 10px 20px auto 20px; display:flex; flex-direction: column; justify-content: flex-start;">
			<v-flex xs12 class="top-distance">
				<div style="display: flex; flex-wrap: wrap;">
					<!-- role name field -->
					<!-- <v-text-field
						v-model="roleName"
						:label="lviews.roleName"
						style="calc(width:100% - 32px); max-width: 400px; padding: 0 16px"
						maxlength="140"
						counter
						clearable
						:error="isRoleError"
						:error-messages="roleErrors"
						@input="() => {this.checkRoleName(roleName);}"
					></v-text-field> -->
					<v-text-field
						v-model="roleName"
						:label="lviews.roleName"
						style="calc(width:100% - 32px); max-width: 400px; padding: 0 16px"
						maxlength="140"
						counter
						clearable
						:error="isRoleError"
						:error-messages="roleErrors"
						@input="() => {this.checkRoleName(roleName);}"
						@click:clear="() => {this.showEmptyError();}"
					></v-text-field>
				</div>
			</v-flex>

			<v-list
				
			>
				<template
					v-for="moduleItem in dataSharing.modules"
				>
					<v-list-item
						
						:key="`module-${moduleItem.moduleId}`"
						style="background-color: #eeeeee; height: 54px;"
					>
						<div class="perm-wrapper">
							<div class="perm-wrapper-item perm-main-title">
								<div class="perm-title-wrapper">
									{{ moduleItem.moduleName }}
								</div>
							</div>
							<div class="perm-wrapper-item">
								<v-checkbox
									style="margin-left:8px; margin-top: 20px;"
									v-model="moduleItem.mainCheckbox"
									:indeterminate="moduleItem.mainCheckboxIndeterminate"
									@click="switchCheckAllInModule(moduleItem)"
								></v-checkbox>
							</div>

							<div>
							</div>
						</div>
					</v-list-item>

					<!-- <v-divider></v-divider> -->

					<template
						v-for="dsItem in moduleItem.items"
					>
						<template
							v-if="dsItem.permissionItems.length > 0"
						>
							<v-list-item
								:key="`md-${moduleItem.moduleId}-ds-${dsItem.id}`"
								style="height: 58px"
							>
								<div class="perm-wrapper">
									<div class="perm-wrapper-item perm-title">
										<div class="perm-title-wrapper">
											{{ dsItem.name }}
										</div>
									</div>

									<div class="perm-wrapper-item perm-checkbox">
										<v-checkbox
											style="margin-left:8px;"
											v-model="dsItem.mainCheckbox"
											:indeterminate="dsItem.mainCheckboxIndeterminate"
											@click="()=>{switchCheckAllInRow(dsItem);updateModuleMainCheckbox(moduleItem);}"
										></v-checkbox>
									</div>

									<!-- 
										Displayed as:
										1) Multi select (Desktop, screenWidth > 500px)
										2) Button to open a dialog with multiselect (Mobile)
									-->
									<div class="perm-wrapper-item perm-select">
										<div class="perm-select-wrapper">
											<!-- Desktop screen size -->
											<v-select
												v-if="windowWidth > 500"
												v-model="dsItem.selectedItems"
												:items="dsItem.displayItems"
												item-value="id"
												item-text="name"
												:menu-props="{ left: true, 'min-width': '270px', 'max-width': '350px' }"
												multiple
												style="height: 54px; max-height: 54px; overflow: hidden; margin-top: 0; padding-top: 12px"
												:placeholder="lviews.lackOfPermissions"
												@change="onPermissionSelectionChange(dsItem);updateModuleMainCheckbox(moduleItem);"

												class="no-underline-select"
											>

												<template v-slot:item="{item, attrs, on}">
													<v-divider
														v-if="item.divider"
													></v-divider>

													<v-list-item
														v-else
														color="primary"
														v-on="on"
														v-bind="attrs"
														#default="{ active }"
													>
														<v-list-item-action style="margin-right: 12px">
															<v-checkbox
																:input-value="active"
																color="primary"
															></v-checkbox>
														</v-list-item-action>

														<v-list-item-content>
															<v-list-item-title style="white-space:normal;" v-text="item.name"></v-list-item-title>
														</v-list-item-content>
													</v-list-item>
												</template>
											</v-select>

											<!-- Mobile screen size -->
											<v-dialog
												v-else
												v-model="dsItem.dialog"
												max-width="320"
											>
												<template v-slot:activator="{ on, attrs }">
													<v-btn
														text
														fab
														ripple
														small
														v-bind="attrs"
														v-on="on"
													>
														<v-icon color="primary">
															mdi-dots-vertical
														</v-icon>
													</v-btn>
												</template>
												<v-card>
													<v-card-title class="text-h6" style="padding-left: 18px; padding-right: 18px">
														{{dsItem.name}}
													</v-card-title>
													<v-divider></v-divider>
													<v-card-text style="padding-left:0;padding-right:0;padding-bottom:0">
														<!-- Using v-list for compatibility with multiselect -->
														<v-list style="padding: 0">
															<v-list-item-group
																v-model="dsItem.selectedItems"
																@change="onPermissionSelectionChange(dsItem);updateModuleMainCheckbox(moduleItem);"
																multiple
															>
																<template v-for="(item, i) in dsItem.displayItems">
																	<v-divider
																		v-if="item.divider"
																		:key="`divider-${dsItem.id}-${i}`"
																	></v-divider>

																	<v-list-item
																		v-else
																		color="primary"
																		:key="`item-${dsItem.id}-${i}`"
																		:value="item.id"
																	>
																		<template v-slot:default="{ active }">
																			<v-list-item-action style="margin-right: 12px">
																				<v-checkbox
																					:input-value="active"
																					color="primary"
																				></v-checkbox>
																			</v-list-item-action>

																			<v-list-item-content>
																				<v-list-item-title style="white-space:normal;" v-text="item.name"></v-list-item-title>
																			</v-list-item-content>
																		</template>
																	</v-list-item>
																</template>
															</v-list-item-group>
														</v-list>
														<v-divider></v-divider>
													</v-card-text>
													<v-card-actions>
													<v-spacer></v-spacer>
													<v-btn
														color="green darken-1"
														text
														@click="dsItem.dialog = false"
													>
														{{ lviews.accept }}
													</v-btn>
													</v-card-actions>
												</v-card>
											</v-dialog>
										</div>
									</div>
								</div>
								<v-divider class="perm-divider"></v-divider>
							</v-list-item>

						</template>

						
					</template>
				</template>
			</v-list>

			<v-card-actions style="margin-top: 20px; margin-bottom: 50px; padding-right: 0px;">
				<v-spacer></v-spacer>
				<v-btn text @click="cancelEditing()"> {{ lviews.cancel }} </v-btn>
				<v-btn color="primary" 
				@click="saveDataSharing"
				:disabled="isRoleError||isSaveDisabled||roleName==null||roleName==''"
				> {{ lviews.save }} </v-btn>
			</v-card-actions>
		</v-layout>

		<!-- close user dialog confirmation popup -->
		<v-dialog 
			persistent 
			v-model="goBackWithoutSavingConfirmation.visible"
			max-width="295"
		>
			<v-card>
				<v-card-title>Wait</v-card-title>
				<v-card-text style="text-align:justify"> {{lviews.goBackWithoutSavingConfirmationContent }}
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>

					<v-btn color="grey"
						text
						@click="() => { goBackWithoutSavingConfirmation.visible = false; }">
						No
					</v-btn>

					<v-btn color="red darken-1"
						text
						@click="closeDialogConfirmCallback">
						Yes
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- API call notification snackbar -->
		<v-snackbar
			v-model="snackbar.visible"
			:color="snackbar.color"
			max-width="400px"
			timeout="1500"
		>
			{{ snackbar.message }}

			<template v-slot:action="{ attrs }">
				<v-btn
					color="white"
					text
					v-bind="attrs"
					@click="snackbar.visible = false"
				>
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</template>
		</v-snackbar>
	</div>
</template>

<script>
//import axios from 'axios';
const axios = require('axios');
import appConfig from '@/app_config'

export default {
	data: function() {
		return {
			roleName: "",
			initialRoleName: "",
			roleErrors: [],
			isRoleError: false,
			isSaveDisabled: false,
			windowWidth: window.innerWidth,
			ds_groups: null,
			dataSharing: {
				modules: [
					/**
					 * {
					 * 		moduleId:	{Number},
					 * 		moduleName:	{String},
					 *		mainCheckbox: {Bool},
					 *		mainCheckboxIndeterminate: {Bool},
					 * 		items: [
					 * 			{
					 * 				id:	{Number},
					 * 				name: {String},
					 * 				mainCheckbox: <model>,
					 * 				dialog: {Boolean},
					 * 				mainCheckboxIndeterminate: <true/false>,
					 * 				selectedItems: [Number], // which permissions were selected when specific type has no projects
					 * 				displayItems: [], // Same as permissionItems, but may contain a divider. Also enforces this order: VIEW, ADD, CHANGE, DELETE
					 * 				permissionItems: [ // permissions in the module
					 * 					{
					 * 						id: {Number},
					 * 						name: {String},
					 * 						codename: {String},
					 * 						contentType: {String},
					 * 					}
					 * 				],
					 * 				projects: [ // might be an empty array
					 *					{
					 *						id: {Number},
					 *						className: {String},
					 *						name: {String},
					 *						permissions: [
					 *							{
					 *								name: {String},
					 *								codename: {String},
					 *								id: {Number}
					 *							}
					 *						],
					 *						selectedItems: [ // which permissions were selected when specific type has some projects assigned
					 *							{String}, // permission codename
					 *						],
					 *						dialog: {Boolean},
					 *						mainCheckbox: {Boolean},
					 *						mainCheckboxIndeterminate: {Boolean}
					 *					}
					 * 				]
					 * 			}
					 * 		]
					 * }
					 */
				],
				initials: [
					/**
					 * {
					 * 		moduleId: {Number},
					 * 		moduleName: {String},
					 * 		items: [ // types list
					 * 			{
					 * 				id: {Number}, // permission type id
					 * 				name: {String}, // permission type translation
					 * 				selectedItems: [Number], // permissions 
					 * 				projects: [ // permission type projects
					 * 					{
					 * 						id: {Number},
					 * 						name: {String},
					 * 						selectedItems: [
					 * 							{String} // permission codename
					 * 						]
					 * 					}
					 * 				]
					 * 			}
					 * 		]
					 * }
					 */
				]
			},
			goBackWithoutSavingConfirmation: {
				visible: false
			},
			snackbar: {
				visible: false,
				color: "primary",
				message: ""
			},
		}
	},
	props:{
		goBackClicked: Boolean
	},

	computed: {
		dataSharingChangesProvided: {
			get: function() {
				return this.anyChangesProvided();
			}
		},

		lviews: {
			get: function() {
				return this.$t("views.dsConfig");
			}
		},

		lmessages: {
			get: function() {
				return this.$t("commons.messages");
			}
		},
	},

	methods: {
		checkRoleName: function(roleName){
			this.roleErrors = [];
			if(roleName == "" || roleName == null){
				this.isRoleError = true;
				this.roleErrors.push(this.lviews.roleEmpty);
				return false;
			}

			let idx = this.ds_groups.findIndex(x => { return x.name.toLowerCase() == roleName.toLowerCase(); });
			if (idx != -1 && this.ds_groups[idx].id !=this.$route.params.group_id) {
				this.isRoleError = true;
				this.roleErrors.push("Group with that name already exists");
				return false;
			}
			
			this.isRoleError = false;
			this.isSaveDisabled = false;
			return true;
		},
		showEmptyError: function(){
			this.roleErrors = [];
			this.isRoleError = true;
			this.roleErrors.push(this.lviews.roleEmpty);
		},
		fetchGroups(){
			this.ds_groups=[]
			axios({
				method:'get',
				url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/admin/groups/",
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + localStorage.getItem("jwt")
				},
			}).then((response) => {
				if(response.data.ds_groups){
					for (let i=0; i < response.data.ds_groups.length; i++) {
						this.ds_groups.push({
							'id': response.data.ds_groups[i].id,
							'name': response.data.ds_groups[i].name,
						});
					}
				}
			})
			.catch((error) => {
				if (typeof(error.response) === 'undefined'){
					this.snackbar.message = "Application failed to connect to the server. Please check your internet connection.";
					this.snackbar.color = "red"
					this.snackbar.visible=true
				}

				switch(error.response.status){
					case 401:
						// Redirect to login page
						this.$router.push("/");
						return;
					case 403:
						this.$emit('set-state', 'ERROR', "You do not have priviledges to this view");
						return;
					case 429:
						this.snackbar.message = "Server received too many reuqests from your browser. Please wait a minute before trying again.";
						this.snackbar.color = "red"
						this.snackbar.visible=true
						return
					case 400:
						this.snackbar.message = "Server rejected your request. Please make sure your application is up to date (reload the website)";
						this.snackbar.color = "red"
						this.snackbar.visible=true
						return
					case 500:
						this.snackbar.message = "An internal server error has occured. Please report this message to the administrator.";
						this.snackbar.color = "red"
						this.snackbar.visible=true
						return;
					default:
						this.snackbar.message = "An unknown error has occured. That's all we know";
						this.snackbar.color = "red"
						this.snackbar.visible=true
						return;
				}
			})
		},
		saveDataSharing: async function (){
			this.$emit("set-state",  "LOADING_OVERLAYER", this.lmessages.savingChanges);
			var group_id = this.$route.params.group_id;
			let newPermissions = [];
			let newPermissionsProjects = [];

			// let lang = this.$cookies.get("language");
			let lang
			if (lang == null){
				lang = "pl";
			}

			for(var i = 0; i < this.dataSharing.modules.length; i++){
				for(var l = 0; l < this.dataSharing.modules[i].items.length; l++){	
					for(let j = 0; j < this.dataSharing.modules[i].items[l].selectedItems.length; j++){
						newPermissions.push(this.dataSharing.modules[i].items[l].selectedItems[j]);
					}
				}
			}
			let responsePromise = null;
			if (group_id == null || group_id == undefined){
				let groupNewData = {
					permissions: newPermissions,
					name: this.roleName,
					perm_type: 1,
				}
				let apiGroupsPostLink = appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/admin/groups/";
				responsePromise = axios({
					method: "POST",
					url: apiGroupsPostLink,
					headers: {
						'Content-Type': 'application/json',
						'Authorization': 'Bearer ' + localStorage.getItem('jwt')
					},
					data:groupNewData
				});
			}
			else{
				let groupNewData = {
					permissions: newPermissions,
					permissions_projects: newPermissionsProjects,
					name: this.roleName,
				}
				let apiGroupsLink = appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/admin/groups/"+group_id;
				responsePromise = axios({
					method: "PATCH",
					url: apiGroupsLink,
					headers: {
						'Content-Type': 'application/json',
						'Authorization': 'Bearer ' + localStorage.getItem('jwt')
					},
					data:groupNewData
				});
			}
			let response = null;
			try {
				response = await responsePromise;
			} catch (error) {
				this.$emit('set-state', 'default');
				switch(error.response.status){
					case 404:
						this.$emit('set-state', 'ERROR', this.lviews.pageNotFound);
						break;
					case 403:
						this.snackbar.message = "You do not have permissions to do this action.";
						this.snackbar.color = "red"
						this.snackbar.visible=true
						break;
					case 401:
						this.$router.push("/login");
						break;
					case 400:
						if(error.response.data.name[0].code == 'unique'){
							this.$emit('set-state', "ERROR", this.lviews.invalidRoleName);
						}
						else{
							this.$emit('set-state', "ERROR", this.lviews.incorrectRequest);
						}
						break;
					default:
						this.$emit('set-state', "ERROR");
						break;
				}
				console.log(response)
				return null;
			}
			this.$router.go(-1);
		},
		/**
		 * Resize window handler.
		 */
		onResize: function() {
			this.windowWidth = window.innerWidth;
		},


		/**
		 * Finds if any changes were provided.
		 */
		anyChangesProvided: function() {
			if(this.roleName!=this.initialRoleName){
				return true
			}
			let verifyingModule = null;
			// verify each module according to initials
			for (let i = 0; i < this.dataSharing.initials.length; i++) {
				let moduleInit = this.dataSharing.initials[i];  
				
				// verify each module using modules (place where we apply changes)
				for (let j = 0; j < this.dataSharing.modules.length; j++) {
					verifyingModule = this.dataSharing.modules[j];

					// module found => can start verification
					if (moduleInit.moduleId == verifyingModule.moduleId) {
						// initial number of items for module is different than currently held in memory => changes surely have been provided
						if (moduleInit.items.length != verifyingModule.items.length) {
							return true
						}
						// number of items for moduleInit and verifyingModule didn't change => one item could had been removed, but not necessarily
						else {
							for (let k = 0; k < moduleInit.items.length; k++) {
								if(moduleInit.items[k].selectedItems.length!=verifyingModule.items[k].selectedItems.length){
									return true
								}
								else{
									for(let l=0;l<verifyingModule.items[k].selectedItems.length;l++){
										let found = moduleInit.items[k].selectedItems.find(item => item==verifyingModule.items[k].selectedItems[l])
										if(!found){
											return true
										}
									}
								}
							}
						}						
					}
				}
			}

			return false
		},

		/**
		 * Sends request for group 'DataSharing' configuration.
		 * @returns Dictionary with necessary API responses.
		 */
		fetchDataSharing: async function() {
			this.$emit('set-state', 'LOADING', this.lviews.loading);
			let response = null;
			// let lang = this.$cookies.get("language");
			let lang
			if (lang == null) {
				lang = "pl";
			}

			let params = {
				perm_type: 1,
				language: lang
			}

			try {
				let apiUrl = appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/admin/permissions/";
				response = await axios({
					url: apiUrl,
					method: "GET",
					headers: {
						'Authorization': 'Bearer ' + localStorage.getItem('jwt')
					},
					params: params
				});
			} catch (error) {
				// TODO handling errors
				response = null;
			}

			let groupResponse = null;
			let groupId = this.$route.params.group_id;
			
			if(typeof(groupId) !== 'undefined' && groupId != null){
				try {
					groupResponse = await axios({
						url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/admin/groups/" + groupId,
						method: "GET",
						headers: {
							'Authorization': 'Bearer ' + localStorage.getItem('jwt')
						},
					});
				} catch (error) {
					groupResponse = null;
				}
				this.roleName = groupResponse.data.name;
				this.initialRoleName = groupResponse.data.name
			}
			this.$emit('set-state', 'DEFAULT');
			return {
				permissions: response,
				group: groupResponse
			};
		},

		updateAllMainCheckboxes(){
			let moduleItem = null;
			let dsItem = null;
			let projectItem = null;

			for(let i = 0; i < this.dataSharing.modules.length; i++){
				moduleItem = this.dataSharing.modules[i];

				for(let j = 0; j < moduleItem.items.length; j++){
					dsItem = moduleItem.items[j];

					if (dsItem.projects != null){
						for (let k = 0; k < dsItem.projects.length; k++){
							projectItem = dsItem.projects[k];

							this.checkAllPermissionsForProjectIfPossible(projectItem);
						}
					}

					this.onPermissionSelectionChange(dsItem);
				}

				this.updateModuleMainCheckbox(moduleItem);
			}
		},

		assignProjectsPermissions: function(projectPermissions){
			// Assigning project permissions
			let projectPermission = null;
			let sourceIndex = -1;
			let projectId = -1;
			let currentClassname = null;
			let dsItem = null;
			let projectStatIndex = -1; // Which index of projects in dsItem is the starting index for our class_name
			let moduleItem = null;
			for (let i = 0; i < projectPermissions.length; i++){

				projectPermission = projectPermissions[i];
				projectId = projectPermission.project_id;

				if(currentClassname != projectPermission.class_name){
					dsItem = null;
					sourceIndex = 0;
				}
				currentClassname = projectPermission.class_name;

				if(dsItem == null){
					let found = false;
					// Find ds item
					for(let j = 0; j < this.dataSharing.modules.length; j++){
						moduleItem = this.dataSharing.modules[j];
						for(let k = 0; k < moduleItem.items.length; k++){
							dsItem = moduleItem.items[k];

							if(dsItem.projects.length > 0){
								for(let l = 0; l < dsItem.projects.length; l++){
									if(dsItem.projects[l].className == currentClassname){
										projectStatIndex = l;
										sourceIndex = projectStatIndex;
										found = true;
										break;
									}
								}
							}
						}
					}
					if(!found)
						dsItem = null;
				}

				if(dsItem != null){
					// Searching for record
					while(
						sourceIndex < dsItem.projects.length
						&& dsItem.projects[sourceIndex].className == currentClassname
						&& dsItem.projects[sourceIndex].id != projectId
					){
						sourceIndex++;
					}

					// Record found
					if(
						sourceIndex < dsItem.projects.length 
						&& dsItem.projects[sourceIndex].className == currentClassname 
						&& dsItem.projects[sourceIndex].id == projectId
					){
						let value = null;
						for (let key in projectPermission.permissions){
							value = projectPermission.permissions[key];
							if(value){
								dsItem.projects[sourceIndex].selectedItems.push(key);
							}
						}
					}
				}
			}
		},

		/**
		 * Adapts received from server response.
		 * @param response {Object} Response received from group 'DataSharing' API.
		 */
		adaptGroupDataSharingResponse: function(permissionsData, groupDetailsData) {
			let dataSharingData = permissionsData.data.ds;
			// let lang = this.$cookies.get("language");
			let lang
			if (lang == null) {
				lang = "pl";
			}

			let groupPermissions = (groupDetailsData == null || groupDetailsData.data.permissions == null) ? [] : groupDetailsData.data.permissions;
			this.dataSharing.modules = [];
			this.dataSharing.initials = [];

			
			for (let i = 0; i < dataSharingData.length; i++) {
				
				let moduleData = {
					moduleId: i,
					moduleName: dataSharingData[i].translation,
					mainCheckbox: false,
					mainCheckboxIndeterminate: false,
					items: []
				};

				let initialData = {
					moduleId: i,
					moduleName: dataSharingData[i].translation,
					items: []
				};

				

				for (let j = 0; j < dataSharingData[i].type.length; j++) {

					let moduleDataItem = {
						id: j,
						name: dataSharingData[i].type[j].translation,
						mainCheckbox: false,
						mainCheckboxIndeterminate: false,
						dialog: false,
						displayItems: [],
						selectedItems: [],
						permissionItems: [],
						// projects: [
							/**
							 * {
							 * 		id: {Number},
							 * 		name: {String},
							 * 		permissionItems: [
							 * 			{
							 * 				name: {String},
							 * 				codename: {String},
							 * 				id: {Number}
							 * 			}
							 *		],
							 *		selectedItems: [
							 *	 		{String} // permissions codename
							 *		],
							 *		mainCheckbox: {Boolean},
							 *		mainCheckboxIndeterminate: {Boolean}
							 * }
							 */
						// ],
					};

					let initialDataItem = {
						id: j,
						moduleName: dataSharingData[i].type[j].translation,
						selectedItems: [],
					};

					let type = dataSharingData[i].type[j];
					
					// Enforcing order: VIEW, ADD, CHANGE, DELETE
					let topItems = [ null, null, null, null ];
					let bottomItems = [];

					for (let k = 0; k < type.permissions.length; k++) {
						
						let permissionObj = {
							id: type.permissions[k].id,
							name: type.permissions[k].name,
							codename: type.permissions[k].codename,
							contentType: type.permissions[k].content_type
						}

						// assigning permissionItems (for select)
						moduleDataItem.permissionItems.push(permissionObj);

						// assigning selected options
						if (groupPermissions.includes(permissionObj.id)) {
							moduleDataItem.selectedItems.push(permissionObj.id);
							initialDataItem.selectedItems.push(permissionObj.id);
						}

						// displaying list handling
						// VIEW
						if (permissionObj.codename.substring(permissionObj.codename.length - 5) == "_view") {
							if (topItems[0] == null) {
								permissionObj.name = this.lviews.access;
								topItems[0] = permissionObj;
							}
							else {
								bottomItems.push(permissionObj);
							}
						}

						// ADD
						else if (permissionObj.codename.substring(permissionObj.codename.length - 4) == "_add") {
							if (topItems[1] == null) {
								permissionObj.name = this.lviews.create;
								topItems[1] = permissionObj;
							}
							else {
								bottomItems.push(permissionObj);
							}
						}

						// CHANGE
						else if (permissionObj.codename.substring(permissionObj.codename.length - 7) == "_change") {
							if (topItems[2] == null) {
								permissionObj.name = this.lviews.edit;
								topItems[2] = permissionObj;
							}
							else {
								bottomItems.push(permissionObj);
							}
						}

						// DELETE
						else if (permissionObj.codename.substring(permissionObj.codename.length - 7) == "_delete") {
							if (topItems[3] == null) {
								permissionObj.name = this.lviews.delete;
								topItems[3] = permissionObj;
							}
							else {
								bottomItems.push(permissionObj);
							}
						}

						// CUSTOM
						else {
							bottomItems.push(permissionObj);
						}
					}                    

					let displayItems = [];
					let count = 0;
					for (let a = 0; a < 4; a++) {
						if (topItems[a] != null) {
							count++;
							displayItems.push(topItems[a]);
						}
					}

					if (count > 0 && bottomItems.length > 0) {
						// add divider
						displayItems.push({ divider: true });
					}
					for (let k = 0; k < bottomItems.length; k++) {
						displayItems.push(bottomItems[k]);
					}

					moduleDataItem.displayItems = displayItems;

					if (moduleDataItem.selectedItems.length == type.permissions.length && moduleDataItem.selectedItems.length > 0) {
						moduleDataItem.mainCheckbox = true;
						moduleDataItem.mainCheckboxIndeterminate = false;
					}
					else if (moduleDataItem.selectedItems.length > 0) {
						moduleDataItem.mainCheckbox = false;
						moduleDataItem.mainCheckboxIndeterminate = true;
					}
					else if (moduleDataItem.selectedItems.length == 0) {
						moduleDataItem.mainCheckbox = false;
						moduleDataItem.mainCheckboxIndeterminate = false;
					}

					moduleData.items.push(moduleDataItem);
					initialData.items.push(initialDataItem);
				}

				this.dataSharing.modules.push(moduleData);
				this.dataSharing.initials.push(initialData);
			}
		},

		/**
		 * Updates group 'DataSharing' view data.
		 */
		updatePageData: async function() {
			let response = null;
			try {
				response = await this.fetchDataSharing();
			} catch (error) {
				response = null;
				// handler error
			}

			if (response != null) {
				this.adaptGroupDataSharingResponse(response.permissions, response.group);
				this.updateAllMainCheckboxes();
			}
		},

		updateModuleMainCheckbox(moduleItem){
			if(moduleItem.items.length == 0){
				moduleItem.mainCheckbox = true;
				moduleItem.mainCheckboxIndeterminate = false;
				return;
			}

			if(moduleItem.items[0].mainCheckboxIndeterminate){
				moduleItem.mainCheckbox = false;
				moduleItem.mainCheckboxIndeterminate = true;
				return;
			}

			let state = moduleItem.items[0].mainCheckbox;
			let dsItem = null;

			for(let i = 1; i < moduleItem.items.length; i++){
				dsItem = moduleItem.items[i];
				if(dsItem.mainCheckboxIndeterminate || state != dsItem.mainCheckbox){
					moduleItem.mainCheckbox = false;
					moduleItem.mainCheckboxIndeterminate = true;
					return;
				}
			}

			moduleItem.mainCheckbox = state;
			moduleItem.mainCheckboxIndeterminate = false;
		},

		switchCheckAllInModule: function(moduleItem) {
			if(moduleItem.mainCheckbox){
				this.setCheckAllInModule(moduleItem, true);
			}
			else{
				this.setCheckAllInModule(moduleItem, false);
			}
		},
		setCheckAllInModule: function(moduleItem, value) {
			if(value){
				for(let i = 0; i < moduleItem.items.length; i++){
					this.setCheckAllInRow(moduleItem.items[i], true);
				}
			}
			else{
				for(let i = 0; i < moduleItem.items.length; i++){
					this.setCheckAllInRow(moduleItem.items[i], false);
				}
			}
		},

		switchCheckAllInRow: function(dataSharingModuleItem) {
			if (dataSharingModuleItem.mainCheckbox) {
				this.setCheckAllInRow(dataSharingModuleItem, true);
			}
			else {
				this.setCheckAllInRow(dataSharingModuleItem, false);
			}
		},

		setCheckAllInRow: function(dataSharingModuleItem, value) {
			// Regular permissions (dropdown list)
			if(dataSharingModuleItem.permissionItems.length > 0){
				if (value) {
					dataSharingModuleItem.selectedItems.splice(0, dataSharingModuleItem.selectedItems.length);
					
					for(let i = 0; i < dataSharingModuleItem.permissionItems.length; i++){
						dataSharingModuleItem.selectedItems.push(dataSharingModuleItem.permissionItems[i].id);
					}

					dataSharingModuleItem.mainCheckbox = true;
					dataSharingModuleItem.mainCheckboxIndeterminate = false;
				}
				else {
					dataSharingModuleItem.selectedItems.splice(0, dataSharingModuleItem.selectedItems.length);
					dataSharingModuleItem.mainCheckbox = false;
					dataSharingModuleItem.mainCheckboxIndeterminate = false;
				}
			}
		},

		onPermissionSelectionChange: function(dataSharingModuleItem) {
			// Regular permissions
			if(dataSharingModuleItem.permissionItems.length > 0){
				if (dataSharingModuleItem.selectedItems.length == dataSharingModuleItem.permissionItems.length) {
					this.setCheckAllInRow(dataSharingModuleItem, true);
				}
				else if (dataSharingModuleItem.selectedItems.length > 0) {
					dataSharingModuleItem.mainCheckboxIndeterminate = true;
					dataSharingModuleItem.mainCheckbox = false;
				}
				else {
					this.setCheckAllInRow(dataSharingModuleItem, false);
				}
			}
			// Instance (project) permissions item
			else if (dataSharingModuleItem.projects.length > 0){
				if (dataSharingModuleItem.projects[0].mainCheckboxIndeterminate){
					dataSharingModuleItem.mainCheckbox = false;
					dataSharingModuleItem.mainCheckboxIndeterminate = true;
				}
				else{
					let state = dataSharingModuleItem.projects[0].mainCheckbox;
					let allowChange = true;

					for(let i = 1; i < dataSharingModuleItem.projects.length; i++){
						// If any is in indeterminate state, set to indeterminate
						if(dataSharingModuleItem.projects[i].mainCheckboxIndeterminate){
							dataSharingModuleItem.mainCheckbox = false;
							dataSharingModuleItem.mainCheckboxIndeterminate = true;
							allowChange = false;
							break;
						}
						// If any checkbox states do not match, set to indeterminate
						else if(dataSharingModuleItem.projects[i].mainCheckbox != state){
							dataSharingModuleItem.mainCheckbox = false;
							dataSharingModuleItem.mainCheckboxIndeterminate = true;
							allowChange = false;
							break;
						}
					}

					// all checkboxes matched
					if (allowChange){
						dataSharingModuleItem.mainCheckbox = state;
						dataSharingModuleItem.mainCheckboxIndeterminate = false;
					}
				}
			}
			else{
				dataSharingModuleItem.mainCheckbox = true;
				dataSharingModuleItem.mainCheckboxIndeterminate = false;
			}
		},

		/**
		 * Uploads data sharing changes to server
		 */
		uploadChanges: async function() {
			
		},

		/**
		 * Checks all projects for specific type.
		 * @param typeItem {object} Type that should contain all permissions marked for all projects.
		 */
		// switchCheckAllProjectsInRow: function(typeItem) {
		// },

		/**
		 * Checks/unchecks all possible permissions in row.
		 * @param project {object} Considered project to mark/unmark all possible permissions.
		 */
		switchCheckAllSpecificProjectPermissionsInRow: function(project) {
			if (!project.mainCheckbox) {
				// clear all checked permissions
				this.setCheckAllSpecificProjectPermissionsInRow(project, false);
			}
			else {
				this.setCheckAllSpecificProjectPermissionsInRow(project, true);
			}
		},
		setCheckAllSpecificProjectPermissionsInRow: function(project, value){
			if (!value) {
				// clear all checked permissions
				project.selectedItems.splice(0, project.selectedItems.length);
				project.mainCheckbox = false;
			}
			else {
				// check all permissions
				project.selectedItems.splice(0, project.selectedItems.length);
				for (let i = 0; i < project.permissions.length; i++) {
					project.selectedItems.push(project.permissions[i].codename);
				}
				project.mainCheckbox = true;
			}
			project.mainCheckboxIndeterminate = false;
		},

		/**
		 * Checks if all permissions were selected (if yes, it's handled properly => mainCheckbox = true).
		 * @param project {object} Project to check if all permissions were selected.
		 */
		checkAllPermissionsForProjectIfPossible: function(project) {
			// all permissions were selected
			if (project.selectedItems.length == project.permissions.length && project.permissions.length > 0) {
				project.mainCheckbox = true;
				project.mainCheckboxIndeterminate = false;
			}
			// some permissions were selected
			else if (project.selectedItems.length > 0) {
				project.mainCheckbox = false;
				project.mainCheckboxIndeterminate = true;
			}
			// no permission selected for specific project
			else {
				project.mainCheckbox = false;
				project.mainCheckboxIndeterminate = false;
			}
		},
		closeDialogConfirmCallback: function() {
			this.$emit('disable-go-back', false)
			this.goBackWithoutSavingConfirmation.visible=false
			this.$router.go(-1);
		},
		cancelEditing(){
			if(this.anyChangesProvided()){
				this.goBackWithoutSavingConfirmation.visible=true
			}
			else{
				this.$router.go(-1);
			}
		}
	},

	mounted: async function() {
		if(!localStorage.getItem('tenantSlug')){
			this.$router.push('/choose-tenant');
			return
		}
		this.$emit("set-state", "default");
		this.$emit('set-title', this.lviews.dsConfigTitle);
		this.$emit('getGoBackLink', "/admin/permissions");
		// this.$emit('set-display', 'BACK_WINDOW');

		this.$nextTick(() => {
			window.addEventListener('resize', this.onResize);
		});

		this.fetchGroups();
		await this.updatePageData();
	},

	beforeDestroy: function() {
		window.removeEventListener('resize', this.onResize);
	},

	beforeMount: function() {
		this.windowWidth = window.innerWidth;
	},

	watch: {
		goBackClicked: function(){
			this.goBackWithoutSavingConfirmation.visible=true
			this.$emit('go-back-clicked-taken');
		},
		dataSharingChangesProvided: function(){
			if(this.dataSharingChangesProvided){
				this.$emit('disable-go-back', true)
			}
			else{
				this.$emit('disable-go-back', false)
			}
		}
	}
}
</script>

<style>
.module-header {
	background-color: lightgrey !important;
	color: grey !important;
}

.perm-wrapper {
	width: 100%;
	display: grid;
	grid-gap: 0;
	grid-template-columns: 250px 48px 1fr;
}
.perm-wrapper-subcategory-activator {
	width: 100%;
	display: grid;
	grid-gap: 0;
	grid-template-columns: 250px 48px 1fr;
}
.perm-wrapper-subcategory {
	width: 100%;
	display: grid;
	grid-gap: 0;
	padding-left: 20px;
	grid-template-columns: 230px 48px 1fr;
}
.perm-main-title {
	font-weight: bold;
	font-size: 16px;
}
.perm-title {
	padding-bottom: 0;
}
.perm-checkbox {
	padding-top: 5px;
}
.perm-select {
	padding-top: 0;
}
.perm-divider {
	margin-top: 0;
}
.perm-title-wrapper, .perm-select-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	height: 100%;
}

.perm-subcategory > .v-list-item > .v-list-group__header__append-icon {
	margin-left: 0 !important;
	min-width: 40px !important;
}

.perm-wrapper-item-center-vert {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

@media only screen and (max-width: 550px) {
	.perm-wrapper {
		grid-template-columns: 200px 48px 1fr;
	}
	.perm-wrapper-subcategory {
		grid-template-columns: 180px 48px 1fr;
	}
	.perm-wrapper-subcategory-activator{
		grid-template-columns: 200px 48px 1fr;
	}
}

.v-list .perm-wrapper-subcategory-activator > .perm-checkbox .v-icon {
	color: rgba(0, 0, 0, 0.54);
}

@media only screen and (max-width: 500px) {
	.perm-wrapper {
		grid-template-columns: 1fr 48px 40px;
	}
	.perm-wrapper-subcategory {
		grid-template-columns: 1fr 48px 40px;
	}
	.perm-wrapper-subcategory-activator {
		grid-template-columns: 1fr 48px;
	}
	.perm-title {
		padding-bottom: 0;
	}
	.perm-divider {
		margin-top: 0;
	}

	.perm-subcategory > .v-list-item > .v-list-group__header__append-icon {
		margin-left: 0 !important;
		min-width: 40px !important;
		padding-right: 8px;
	}
}

.perm-sublist-expander-wrapper-desktop {
	width: 100%;
	display: grid;
	grid-gap: 0;
	grid-template-columns: 1fr 24px;
}
.perm-sublist-expander-wrapper-desktop > div {
	display: flex;
	flex-direction: column;
	justify-content: center;
	color: #009f3d;
}

.top-distance {
	padding-top: 20px;
}

@media only screen and (max-width: 960px){
	.top-distance {
		padding-top: 10px;
	}
}

</style>